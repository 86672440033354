import React from "react"
import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentServices from "../components/organisms/ContentServices/ContentServices"
import { graphql } from "gatsby"

const Servicios = (props) => {
  const { allContentfulContenidoServicicios } = props.data

  return (
    <Layout>
      <SEO
        title={allContentfulContenidoServicicios.nodes[0].metaTitulo}
        description={allContentfulContenidoServicicios.nodes[0].metaDescription.metaDescription}
      />
      <ContentServices />
    </Layout>
  )
}

export const query = graphql`
    query Meta_Query_Services{
        allContentfulContenidoServicicios {
            nodes {
                metaTitulo
                metaDescription {
                    metaDescription
                }
            }
        }
    }
`

export default Servicios
