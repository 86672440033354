import React from "react"
import Banner from "../../molecules/Banner/Banner"
import InfoServices from "../../molecules/InfoServices/InfoServices"
import ListServices from "../ListServices/ListServices"

import { graphql, useStaticQuery } from "gatsby"

const ContentServices = () => {

  const { allContentfulContenidoServicicios } = useStaticQuery(
    graphql`
        query MyQuery {
            allContentfulContenidoServicicios {
                nodes {
                    metaTitulo
                    metaDescription {
                        metaDescription
                    }
                    imagenBanner {
                        description
                        fluid(maxWidth: 1980) {
                            aspectRatio
                            base64
                            sizes
                            srcSet
                            src
                        }
                    }
                    tituloBanner
                    descripcinPrincipal {
                        raw
                    }
                    servicios {
                        descripcion {
                            raw
                        }
                        imagen {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                srcSet
                                src
                            }
                            description
                        }
                    }
                }
            }
        }
    `
  )

  const contentInfo = allContentfulContenidoServicicios.nodes[0]

  return (

    <>
      <Banner title={contentInfo.tituloBanner} image={contentInfo.imagenBanner} type="fluid" />
      <InfoServices info={contentInfo.descripcinPrincipal} />
      <ListServices services={contentInfo.servicios} />
    </>
  )
}

export default ContentServices
