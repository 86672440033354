import React from "react"
import "./ListServices.scss"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

const ListServices = ({services}) => {

  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="list-services__service-title">{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="list-services__service-description">{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-services__service-description">{children}</ul>
    }
  }

  return (
    <section className="list-services">
      <div className="list-services__wrapper">

        <div className="list-services__list">
          {services.map((service, index) => {
            return (
              <>
                <div className="list-services__service">
                  <div className="list-services__service-txt">
                    {documentToReactComponents(JSON.parse(service.descripcion.raw), options)}
                  </div>
                  <div className="list-services__service-img">
                    <Img
                      className="img-fluid"
                      loading="lazy"
                      itemProp="image"
                      fluid={service.imagen.fluid}
                      alt={service.imagen.description}
                      draggable={false}
                    />
                  </div>

                </div>
                {
                  (index + 1 !== services.length) && (
                    <div className="list-services__service-divider" />

                  )
                }
              </>
            )
          })}

        </div>

      </div>
    </section>
  )
}

export default ListServices
