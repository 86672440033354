import React from "react"
import "./InfoServices.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"


const InfoServices = ({info}) => {

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="info-services__title">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="info-services__paragraph">{children}</p>
    }
  }



  return (
    <section className="info-services">
      <div className="info-services__wrapper">
        {documentToReactComponents(JSON.parse(info.raw), options)}
      </div>
    </section>
  )
}

export default InfoServices
